<template>
  <tags-input
    ref="tags"
    v-model="selectedTags"
    :add-tags-on-comma="true"
    :addTagsOnBlur="false"
    :existing-tags="existingTags"
    :sort-search-results="false"
    :typeahead="true"
    :typeahead-activation-threshold="1"
    :typeahead-hide-discard="true"
    element-id="tags"
    typeahead-style="dropdown"
    @change="onInputTag"
  ></tags-input>
</template>

<script>
import VoerroTagsInput from "@voerro/vue-tagsinput";
import { mapActions, mapState } from "vuex";
import * as TYPES from "@/core/services/store/modules/domains/types";
import { debounce } from "@/core/services/services";
import "@yaireo/tagify/dist/tagify.css";

export default {
  name: "Tags",
  components: { "tags-input": VoerroTagsInput },
  props: {
    tags: Array,
  },
  data: () => ({
    search_tag: "",
    selectedTags: [],
    existingTags: [],
  }),

  computed: {
    ...mapState({
      tags_list: (state) => state.domains.tags,
    }),
  },
  methods: {
    ...mapActions({
      loadTags: `domains/${TYPES.GET_TAGS}`,
    }),
    onInputTag: function (e) {
      this.search_tag = e;
      this.$refs.tags.searchSelection = -1;
      this.onWaitChange();
    },

    onWaitChange: function () {
      this.loadTags(`name=${this.search_tag}`);
    },
    getTagsList: function () {
      return this.selectedTags.map((item) => item.value);
    },
  },
  watch: {
    tags(newValue) {
      this.selectedTags = [
        ...newValue.map((item) => ({ value: item, key: item })),
      ];
    },
    tags_list(newValue) {
      let current_list = this.getTagsList();
      let list = [
        ...newValue.map((item) => ({ value: item.name, key: item.name })),
      ];
      this.existingTags = list.filter(
        (item) => !current_list.includes(item.key)
      );
      this.$refs.tags.searchResults = this.existingTags;
      this.loading = true;
    },
  },
  mounted() {
    this.onWaitChange = debounce(this.onWaitChange, 500);
    this.selectedTags = [
      ...this.tags.map((item) => ({ value: item, key: item })),
    ];
  },
};
</script>

<style scoped>
@import "../../assets/sass/tags.css";
</style>
